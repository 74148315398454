import { LOGGED_IN_DATA, RESET_LOGGED_IN_DATA } from "../../constants";

export const loggedInData = (payload) => {
  return {
    type: LOGGED_IN_DATA,
    payload: payload,
  };
};

export const resetLoginData = () => {
  return {
    type: RESET_LOGGED_IN_DATA,
  };
};
