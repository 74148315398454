import { combineReducers } from "redux";

import login from "./Login";
import loggedInData from "./LoggedInData";
import toaster from "./Toaster";
import loading from "./Loading";
import checkLogin from "./CheckLogin";
import dashboardMetrics from "./DashboardMetrics";
import communityList from "./CommunityList";
import getReferralCode from "./GetReferralCode";
import logout from "./Logout";
import verifyOtp from "./VerifyOtp";
import qualifiedBattles from "./QualifiedBattles";

export default combineReducers({
  login,
  loggedInData,
  toaster,
  loading,
  checkLogin,
  dashboardMetrics,
  communityList,
  getReferralCode,
  logout,
  verifyOtp,
  qualifiedBattles,
});
