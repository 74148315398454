import axios from "axios";
import { urls } from "../../urls";
import { loggedInData } from "../LoggedInData";

import {
  DASHBOARD_METRICS_INIT,
  DASHBOARD_METRICS_SUCCESS,
  DASHBOARD_METRICS_FAILED,
  DASHBOARD_METRICS_RESET,
} from "../../constants";

export const init = () => {
  return {
    type: DASHBOARD_METRICS_INIT,
  };
};

export const success = (res) => {
  return {
    type: DASHBOARD_METRICS_SUCCESS,
    payload: res.data,
  };
};

export const failed = (error) => {
  return {
    type: DASHBOARD_METRICS_FAILED,
    payload: error.response.data || { message: error.message, code: 400 },
  };
};

export const reset = () => {
  return {
    type: DASHBOARD_METRICS_RESET,
  };
};

export const dashboardMetrics = (loginData, userName) => {
  return async (dispatch) => {
    dispatch(init());
    return await axios
      .get(urls.dashboardMetrics.replace("{user_name}", userName))
      .then((res) => {
        dispatch(loggedInData({ ...loginData, user_image: res.data.data.user_image }));
        dispatch(success(res));
      })
      .catch((err) => {
        dispatch(failed(err));
        setTimeout(() => {
          dispatch(reset());
        }, 3000);
      });
  };
};
