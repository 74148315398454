import React from "react";

import { LoaderStyle } from "./style";

const Loader = ({ size, backgroundColor, isScreen = false }) => {
  return (
    <LoaderStyle size={size} backgroundColor={backgroundColor} className={isScreen ? "full" : ""}>
      <div className="loader"></div>
    </LoaderStyle>
  );
};

export default Loader;
