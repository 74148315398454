import { LOGIN_FAILED, LOGIN_INIT, LOGIN_RESET, LOGIN_SUCCESS } from "../../constants";

const initialState = {
  loading: false,
  code: "",
  success: false,
  data: {},
  error: "",
};

const login = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOGIN_INIT:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        code: action.payload.code,
        success: action.payload.success,
        data: action.payload.data,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        code: action.payload.code || action.payload.status,
        success: action.payload.success,
        data: {},
        error: action.payload.message || action.payload.error_message || action.payload.error,
      };
    case LOGIN_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default login;
