import axios from "axios";
import { getToken } from "../../../Utils/common";
import { urls } from "../../urls";

import { LOGGED_ATTRIBUTES } from "../../../constants";
import { LOGOUT_FAILED, LOGOUT_INIT, LOGOUT_RESET, LOGOUT_SUCCESS } from "../../constants";
import { resetLoginData } from "../LoggedInData";

export const init = () => {
  return {
    type: LOGOUT_INIT,
  };
};

export const success = (res) => {
  clearAllStorage();
  return {
    type: LOGOUT_SUCCESS,
    payload: res.data,
  };
};

export const failed = (error) => {
  return {
    type: LOGOUT_FAILED,
    payload: error.response.data || { message: error.message, code: 400 },
  };
};

export const reset = () => {
  return {
    type: LOGOUT_RESET,
  };
};

export const clearAllStorage = () => {
  sessionStorage.clear();
  localStorage.clear();
  LOGGED_ATTRIBUTES.forEach((item) => {
    localStorage.removeItem(item);
  });
};

export const logout = () => {
  return async (dispatch) => {
    dispatch(resetLoginData());
    clearAllStorage();
  };
  // return async (dispatch) => {
  //   dispatch(init());
  //   return await axios
  //     .post(urls.logout, "", {
  //       headers: {
  //         Authorization: getToken(),
  //       },
  //     })
  //     .then((res) => {
  //       dispatch(success(res));
  //     })
  //     .catch((err) => {
  //       dispatch(failed(err));
  //     });
  // };
};
