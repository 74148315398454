import _ from "lodash";

export const blockInvalidChar = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

export const getLocalStorageItem = (item) => {
  let storageItem = localStorage.getItem(item);
  return storageItem;
};

export const setLocalStorageItem = (item, value) => {
  return localStorage.setItem(item, value);
};

export const getToken = () => {
  const token = getLocalStorageItem("token");
  return token;
};

export const convertToPascalCase = (str) => {
  let string = "";
  if (str) {
    string = str.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
      return g1.toUpperCase() + g2.toLowerCase();
    });
  }
  return string;
};

export const formatNumber = (num) => {
  let res = num.toLocaleString("en-IN");
  return res;
};

export const formatUSCurrency = (num) => {
  let formatter = new Intl.NumberFormat().format(num);
  return formatter;
};

export const generateDropdown = (value, hasAll = false) => {
  let arr = hasAll ? [{ id: 0, label: "All", value: "All" }] : [];
  if (value) {
    _.sortBy(value, ["id"]).forEach((item) => {
      if (item.name) {
        arr.push({ label: item.name, value: item.name, id: item.id });
      }
    });
  }
  return arr;
};

export const toHoursAndMinutes = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return { hours, minutes };
};

export const secondsToHM = (d) => {
  d = Number(d);
  var hours = Math.floor(d / 3600);
  var minutes = Math.floor((d % 3600) / 60);

  return { hours, minutes };
};
