import axios from "axios";
import { useDispatch } from "react-redux";
// import { getLocalStorageItem, getToken } from "../Utils/common";
import { setUpdatedToasterMessage } from "./actions";

// const auth = getLocalStorageItem("auth");

const Axios = () => {
  const dispatch = useDispatch();

  axios.interceptors.request.use(
    (req) => {
      req.baseURL = process.env.REACT_APP_API_URL;
      // if (auth) {
      //   req.headers = {
      //     "Content-Type": "application/json",
      //     Authorization: getToken(),
      //   };
      // }
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      //   if (err.response.status === 401) {
      //     const {user} = store.getState()['user']
      //     store.dispatch(logoutUser(user))

      //     toast.warning('Access token expired, Please login again.', {
      //       toastId: "token-expired"
      //     })
      //     return Promise.reject(err);
      //   }
      dispatch(setUpdatedToasterMessage(err.response.data.message || err.message, "error", 3000));
      return Promise.reject(err);
    }
  );
  return null;
};

export default Axios;
