import {
  VERIFY_OTP_INIT,
  VERIFY_OTP_FAILED,
  VERIFY_OTP_RESET,
  VERIFY_OTP_SUCCESS,
} from "../../constants";

const initialState = {
  loading: false,
  data: {},
  code: "",
  success: false,
  error: "",
};

const verifyOtp = (state = initialState, action = {}) => {
  switch (action.type) {
    case VERIFY_OTP_INIT:
      return {
        ...state,
        loading: true,
      };
    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        code: action.payload.code,
        success: action.payload.success,
        data: action.payload.data || {},
        error: "",
      };
    case VERIFY_OTP_FAILED:
      return {
        ...state,
        loading: false,
        code: action.payload.code,
        success: action.payload.success,
        data: {},
        error: action.payload.data.message || action.payload.data,
      };

    case VERIFY_OTP_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default verifyOtp;
