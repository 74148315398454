import axios from "axios";
import { setLocalStorageItem } from "../../../Utils/common";
import { loggedInData } from "../index";

import {
  CHECK_LOGIN_INIT,
  CHECK_LOGIN_SUCCESS,
  CHECK_LOGIN_FAILED,
  CHECK_LOGIN_RESET,
} from "../../constants";
import { urls } from "../../urls";

export const init = () => {
  return {
    type: CHECK_LOGIN_INIT,
  };
};

export const success = (res) => {
  return {
    type: CHECK_LOGIN_SUCCESS,
    payload: res.data,
  };
};

export const failed = (error) => {
  return {
    type: CHECK_LOGIN_FAILED,
    payload: error.response.data || { message: error.message, code: 400 },
  };
};

export const reset = () => {
  return {
    type: CHECK_LOGIN_RESET,
  };
};

export const checkLogin = (userName) => {
  return async (dispatch) => {
    dispatch(init());
    return await axios
      .post(urls.checkLogin, {
        user_name: userName,
      })
      .then((res) => {
        setLocalStorageItem("profile", userName);
        setLocalStorageItem("user_id", res.data.data.user_id);
        dispatch(loggedInData({ ...res.data.data, profile: userName }));
        dispatch(success(res));
      })
      .catch((err) => {
        dispatch(failed(err));
        setTimeout(() => {
          dispatch(reset());
        }, 3000);
      });
  };
};
