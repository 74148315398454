import {
  TOASTER_MESSAGE_ALERT,
  REMOVE_TOASTER_MESSAGE_ALERT,
} from "../../constants";

export const removeUpdatedToasterMessage = () => {
  return {
    type: REMOVE_TOASTER_MESSAGE_ALERT,
  };
};

export const setUpdatedToasterMessage = (
  toasterMessage,
  status,
  timeOut = 3000
) => {
  return {
    type: TOASTER_MESSAGE_ALERT,
    payload: {
      toasterMessage,
      status,
      timeOut,
    },
  };
};
