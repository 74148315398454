export const LOGIN_INIT = "LOGIN_INIT";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_RESET = "LOGIN_RESET";

export const LOGOUT_INIT = "LOGOUT_INIT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILED = "LOGOUT_FAILED";
export const LOGOUT_RESET = "LOGOUT_RESET";

export const TOASTER_MESSAGE_ALERT = "TOASTER_MESSAGE_ALERT";
export const REMOVE_TOASTER_MESSAGE_ALERT = "REMOVE_TOASTER_MESSAGE_ALERT";

export const VIEW_LOADING = "VIEW_LOADING";

export const LOGGED_IN_DATA = "LOGGED_IN_DATA";
export const RESET_LOGGED_IN_DATA = "RESET_LOGGED_IN_DATA";

export const CHECK_LOGIN_INIT = "CHECK_LOGIN_INIT";
export const CHECK_LOGIN_SUCCESS = "CHECK_LOGIN_SUCCESS";
export const CHECK_LOGIN_FAILED = "CHECK_LOGIN_FAILED";
export const CHECK_LOGIN_RESET = "CHECK_LOGIN_RESET";

export const DASHBOARD_METRICS_INIT = "DASHBOARD_METRICS_INIT";
export const DASHBOARD_METRICS_SUCCESS = "DASHBOARD_METRICS_SUCCESS";
export const DASHBOARD_METRICS_FAILED = "DASHBOARD_METRICS_FAILED";
export const DASHBOARD_METRICS_RESET = "DASHBOARD_METRICS_RESET";

export const COMMUNITY_LIST_INIT = "COMMUNITY_LIST_INIT";
export const COMMUNITY_LIST_SUCCESS = "COMMUNITY_LIST_SUCCESS";
export const COMMUNITY_LIST_FAILED = "COMMUNITY_LIST_FAILED";
export const COMMUNITY_LIST_RESET = "COMMUNITY_LIST_RESET";

export const GET_REFERRAL_CODE_INIT = "GET_REFERRAL_CODE_INIT";
export const GET_REFERRAL_CODE_SUCCESS = "GET_REFERRAL_CODE_SUCCESS";
export const GET_REFERRAL_CODE_FAILED = "GET_REFERRAL_CODE_FAILED";
export const GET_REFERRAL_CODE_RESET = "GET_REFERRAL_CODE_RESET";

export const VERIFY_OTP_INIT = "VERIFY_OTP_INIT";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILED = "VERIFY_OTP_FAILED";
export const VERIFY_OTP_RESET = "VERIFY_OTP_RESET";

export const QUALIFIED_BATTLES_INIT = "QUALIFIED_BATTLES_INIT";
export const QUALIFIED_BATTLES_SUCCESS = "QUALIFIED_BATTLES_SUCCESS";
export const QUALIFIED_BATTLES_FAILED = "QUALIFIED_BATTLES_FAILED";
export const QUALIFIED_BATTLES_RESET = "QUALIFIED_BATTLES_RESET";
