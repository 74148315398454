import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import loadable from "@loadable/component";

import Axios from "./Store/axios";
import Loader from "./Common/Loader";
import configureStore from "./Store/configureStore";
import "./index.css";
import ScrollToTop from "./Common/ScrollToTop";

const Layout = loadable(() => import("./Layout"));

const store = configureStore(window.__PRELOADED_STATE__);

ReactDOM.render(
  <Provider store={store}>
    <StrictMode>
      <BrowserRouter>
        <Axios />
        <ScrollToTop />
        <Layout fallback={<Loader isScreen />} />
      </BrowserRouter>
    </StrictMode>
  </Provider>,
  document.getElementById("root")
);
