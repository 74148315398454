import axios from "axios";
import { urls } from "../../urls";
import { loggedInData } from "../LoggedInData";

import {
  VERIFY_OTP_INIT,
  VERIFY_OTP_FAILED,
  VERIFY_OTP_RESET,
  VERIFY_OTP_SUCCESS,
} from "../../constants";

export const init = () => {
  return {
    type: VERIFY_OTP_INIT,
  };
};

export const success = (res) => {
  return {
    type: VERIFY_OTP_SUCCESS,
    payload: res.data,
  };
};

export const failed = (error) => {
  return {
    type: VERIFY_OTP_FAILED,
    payload: error || { message: error.message, code: 400 },
  };
};

export const reset = () => {
  return {
    type: VERIFY_OTP_RESET,
  };
};

export const verifyOtp = (loginData, otp, userId) => {
  return async (dispatch) => {
    dispatch(init());
    return await axios
      .post(urls.verifyOtp, {
        otp: otp,
        user_id: userId,
      })
      .then((res) => {
        dispatch(loggedInData({ ...loginData, auth: true }));
        dispatch(success(res));
      })
      .catch((err) => {
        dispatch(failed(err));
        setTimeout(() => {
          dispatch(reset());
        }, 3000);
      });
  };
};
