import axios from "axios";
import { urls } from "../../urls";

import {
  GET_REFERRAL_CODE_INIT,
  GET_REFERRAL_CODE_SUCCESS,
  GET_REFERRAL_CODE_FAILED,
  GET_REFERRAL_CODE_RESET,
} from "../../constants";

export const init = () => {
  return {
    type: GET_REFERRAL_CODE_INIT,
  };
};

export const success = (res) => {
  return {
    type: GET_REFERRAL_CODE_SUCCESS,
    payload: res.data,
  };
};

export const failed = (error) => {
  return {
    type: GET_REFERRAL_CODE_FAILED,
    payload: error.response.data || { message: error.message, code: 400 },
  };
};

export const reset = () => {
  return {
    type: GET_REFERRAL_CODE_RESET,
  };
};

export const getReferralCode = (userId = "") => {
  return async (dispatch) => {
    dispatch(init());
    return await axios
      .get(urls.getReferralCode.replace("{userId}", userId))
      .then((res) => {
        dispatch(success(res));
      })
      .catch((err) => {
        dispatch(failed(err));
        setTimeout(() => {
          dispatch(reset());
        }, 3000);
      });
  };
};
