import { combineReducers } from "redux";

import { LOGOUT_SUCCESS } from "./constants";
import rumbler from "./reducers";

const appRedcucer = combineReducers({
  rumbler,
});

const rootReducer = (state, action) => {
  return appRedcucer(state, action);
};

const initialReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = undefined;
  }
  return rootReducer(state, action);
};

export default initialReducer;
