import axios from "axios";
import { urls } from "../../urls";
import { loggedInData } from "../LoggedInData";

import {
  QUALIFIED_BATTLES_FAILED,
  QUALIFIED_BATTLES_INIT,
  QUALIFIED_BATTLES_SUCCESS,
  QUALIFIED_BATTLES_RESET,
} from "../../constants";

export const init = () => {
  return {
    type: QUALIFIED_BATTLES_INIT,
  };
};

export const success = (res) => {
  return {
    type: QUALIFIED_BATTLES_SUCCESS,
    payload: res.data,
  };
};

export const failed = (error) => {
  return {
    type: QUALIFIED_BATTLES_FAILED,
    payload: error.response.data || { message: error.message, code: 400 },
  };
};

export const reset = () => {
  return {
    type: QUALIFIED_BATTLES_RESET,
  };
};

export const qualifiedBattles = (page, qualifiedBattlesStatus, size, userName) => {
  return async (dispatch) => {
    dispatch(init());
    return await axios
      .get(
        urls.qualifiedBattles
          .replace("{page}", page)
          .replace("{qualifiedBattlesStatus}", qualifiedBattlesStatus)
          .replace("{size}", size)
          .replace("{user_name}", userName)
      )
      .then((res) => {
        dispatch(success(res));
      })
      .catch((err) => {
        dispatch(failed(err));
        setTimeout(() => {
          dispatch(reset());
        }, 3000);
      });
  };
};
